<template>
  <div class="miami">
    <div class="all-area">
      <HeaderSection color="blue"/>
       <Complaint />
    </div>
  </div>
</template>

<script>
import HeaderSection from '../components/Header/HeaderOne';
import Complaint from '../components/Complaint/Complaint';


export default {
  name: 'Coworker',
  components: {
    HeaderSection,
    Complaint
  },
  mounted() {
      this.$store.dispatch('getComplaint');
  }
}
</script>