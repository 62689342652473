<template>
    <section id="complaint" class="section complaint-area d-flex bg-lightest-blue " v-if="complaint">
        <div class="container-fluid my-4">
            <div class="row">
                <div class="col">
                    <h1>{{ complaint.title[lang] }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <vue-markdown :key="lang">{{ complaint.description[lang] }}</vue-markdown>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import VueMarkdown from 'vue-markdown'

    export default {
        components: {
            VueMarkdown
        },
        data() {
            return {
                baseUrl: process.env.VUE_APP_STRAPI_URI
            };
        },
        computed: { 
            ...mapState(['lang', 'complaint'])
        }
    }
</script>

<style lang="css">
    .complaint-area {
        padding-top: 170px;
        padding-bottom: 100px;
        min-height: 500px;
    }
    .complaint-area h1 {
        font-family: Squad;
        font-weight: 800;
        font-size: 60px;
        line-height: 79px;
        color: #082744;
    }
    .complaint-area h3 {
        padding-top: 30px !important;
        font-size: 40px;
        color: #082744;
    }
    .complaint-area h4 {
        font-size: 24px;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #082744;
    }
    .complaint-area p {
        font-size: 18px;
        color: #082744;
    }
    .complaint-area ul {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .complaint-area ol {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .complaint-area li {
        font-size: 18px;
        color: #082744;
        margin-left: 10px;
        list-style-type: circle;
    }
    .complaint-area a {
        color: #74BE59 !important;
    }

    @media (max-width: 575px) {
        .complaint-area h1 {
            font-size: 36px;
            line-height: 38px;
        } 
        .complaint-area h3 {
            font-size: 24px;
            line-height: 26px;
        } 
        .complaint-area p {
            font-size: 18px;
            line-height: 20px;
        } 
    }
</style>